export const navData = {
    ru: {
        main: 'Главная',
        aboutMe: 'Обо мне',
        myProjects: 'Мои проекты',
        contacts: 'Контакты',
    },

    en: {
        main: 'Main',
        aboutMe: 'About Me',
        myProjects: 'My Projects',
        contacts: 'Contacts',
    },

}